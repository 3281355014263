.footer-sec {
    position: relative;
    width: 100vw;
    height: fit-content;
  }
@media screen and (max-width: 1024px) {
    .footer-sec img{
      width: 1024px;
    }
    .footer-container{
        width: 1024px;
    }
}

@media screen and (min-width: 1025px) {
    .footer-sec img{
      width: 100%;
    }
    .footer-container{
        width: 80%;
    }
}


.footer-container{
    position: absolute;
    padding-top: 10%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 300px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    gap: 20px;
}

.footer-right{
   position: relative;
}

/* .text-footer {
    text-align: center;
    padding: 30px 0;
    font-family: 'Ubuntu', sans-serif;
    display: flex;
    justify-content: center;
    color: #00469c;
} */