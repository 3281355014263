.home-page{
    display: flex;
}
.home-container{
    position: relative;
    width: 100vw;
    height: 100vh;
}

.home-section{
    text-align: center;
    justify-content: center;
    position: relative;
}
@media screen and (max-width: 1024px) {
    .home-section img {
      width: 1024px;
    }
    .text-container{
        width: 1024 px;
    }
    .partner-container{
        width:  1024 px;
    }

    .home-section img {
        width: 1024px;
      }
      .joinUs-container img{
        width: 740px;
    }
}

@media screen and (min-width: 1025px) {
    .home-section img {
      width: 100%;
    }
    .text-container{
        width: 70%;
    }
    .partner-container{
        width: 70%;
    }

    .joinUs-container{
        width: 70%;
    }
}
.text-container{
    position: absolute;  
    left: 50%;                        /* horizontal alignment */
    top: 50%;                         /* vertical alignment */
    transform: translate(-50%, -50%);
    height: 500px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;;
    justify-content: center;
    gap: 20px;
    text-shadow: 0px 2px 4px rgba(0,0,0,0.72);
}
.text-container div h1 {
    font-weight: 800;
    transform: translate(-20%,0%);
    font-family: 'Barlow';
    font-style: 'semi-condense-bold';
    font-size: 40px;
    align-self: center;
    color: whitesmoke;
}

.text-container div p {
    font-weight: 100;
    font-family: 'Barlow';
    font-style: 'dondense-medium';
    font-size: 20px;
    align-self: center;
    color: whitesmoke;

}

.partner-container{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -120%);
    height: 300px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
}

.partner-container div h1 {
    font-weight: 800;
    position: relative;
    font-family: 'Barlow';
    font-style: 'semi-condense-bold';
    font-size: 40px;
    align-self: center;
    color: #00469c;
}

.joinUs-container{
    position: absolute;  
    left: 50%;                        /* horizontal alignment */
    top: 50%;                         /* vertical alignment */
    transform: translate(-50%, 0);
    height: 300px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;;
    justify-content: center;
    min-width: 741px;
    gap: 20px;
}